import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M8.25014 4.47727H7.36492C5.84613 4.47727 4.61492 5.70849 4.61492 7.22727V11.9378L3.35839 12.4437C2.50631 12.7868 1.75887 13.595 1.77177 14.6701C1.79053 16.2336 2.32044 18.1733 3.91275 19.7213C5.51008 21.2743 8.08109 22.3409 12.0001 22.3409C15.919 22.3409 18.49 21.2743 20.0874 19.7214C21.6797 18.1733 22.2096 16.2336 22.2284 14.6701C22.2413 13.595 21.4938 12.7868 20.6417 12.4437L19.3853 11.9378V7.22727C19.3853 5.70849 18.154 4.47727 16.6353 4.47727H15.75C15.7377 2.96895 14.5113 1.75 13 1.75H11C9.48885 1.75 8.26235 2.96895 8.25014 4.47727ZM11.7199 9.077L6.11492 11.3338V7.22727C6.11492 6.53692 6.67456 5.97727 7.36492 5.97727H9.00005H15H16.6353C17.3256 5.97727 17.8853 6.53692 17.8853 7.22727V11.3338L12.2802 9.077L12.0001 8.96422L11.7199 9.077ZM9.75025 4.47727H14.2498C14.2377 3.7974 13.6828 3.25 13 3.25H11C10.3173 3.25 9.76237 3.7974 9.75025 4.47727ZM11.2501 10.8832L3.91864 13.8351C3.48837 14.0084 3.26798 14.3448 3.27166 14.6521C3.28674 15.9088 3.71197 17.4341 4.95836 18.6459C6.11217 19.7676 8.05204 20.7004 11.2501 20.8264V10.8832ZM12.7501 10.8832V20.8264C15.9481 20.7004 17.888 19.7676 19.0418 18.6459C20.2882 17.4341 20.7134 15.9088 20.7285 14.6521C20.7322 14.3448 20.5118 14.0084 20.0815 13.8351L12.7501 10.8832Z",
      "clip-rule": "evenodd"
    })
  ]))
}