import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M4.80002 2.40002C4.1635 2.40002 3.55306 2.65288 3.10297 3.10297C2.65288 3.55306 2.40002 4.1635 2.40002 4.80002V18C2.40002 18.9548 2.77931 19.8705 3.45444 20.5456C4.12957 21.2207 5.04525 21.6 6.00002 21.6C6.9548 21.6 7.87048 21.2207 8.54561 20.5456C9.22074 19.8705 9.60002 18.9548 9.60002 18V4.80002C9.60002 4.1635 9.34717 3.55306 8.89708 3.10297C8.44699 2.65288 7.83654 2.40002 7.20002 2.40002H4.80002ZM6.00002 19.2C6.31828 19.2 6.62351 19.0736 6.84855 18.8486C7.0736 18.6235 7.20002 18.3183 7.20002 18C7.20002 17.6818 7.0736 17.3765 6.84855 17.1515C6.62351 16.9265 6.31828 16.8 6.00002 16.8C5.68176 16.8 5.37654 16.9265 5.1515 17.1515C4.92645 17.3765 4.80002 17.6818 4.80002 18C4.80002 18.3183 4.92645 18.6235 5.1515 18.8486C5.37654 19.0736 5.68176 19.2 6.00002 19.2ZM12 17.0916L17.88 11.2116C18.33 10.7616 18.5827 10.1512 18.5827 9.51482C18.5827 8.87843 18.33 8.26809 17.88 7.81802L16.182 6.12002C15.732 5.67009 15.1216 5.41734 14.4852 5.41734C13.8488 5.41734 13.2385 5.67009 12.7884 6.12002L12 6.90842V17.0916ZM19.2 21.6H10.8852L18.0852 14.4H19.2C19.8365 14.4 20.447 14.6529 20.8971 15.103C21.3472 15.5531 21.6 16.1635 21.6 16.8V19.2C21.6 19.8365 21.3472 20.447 20.8971 20.8971C20.447 21.3472 19.8365 21.6 19.2 21.6V21.6Z",
      "clip-rule": "evenodd"
    })
  ]))
}