<script setup lang="ts">
import copy from 'copy-to-clipboard'
import { Ref, computed, inject, onMounted, ref } from 'vue'
import { RouteLocationNormalizedLoaded, Router, RouterView, useRoute, useRouter } from 'vue-router'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast, showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'
import { Trip } from '@ankor-io/common/trip/Trip'
import { OutlineEye, OutlineShare } from '@ankor-io/icons/outline'
import { SolidColorSwatch, SolidHome, SolidRoute, SolidYacht } from '@ankor-io/icons/solid'

import Loader from '@/components/Loader.vue'
import { AuthenticationContext } from '@/iam/types'
import { useProposalService } from '@/services/proposal/ProposalService'

const props = defineProps<{
  uri?: string
  items?: string
}>()

const bannerOpen: Ref<boolean> = ref(false)
const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const route: RouteLocationNormalizedLoaded = useRoute()
const router: Router = useRouter()

const presentationNameEl: Ref<any> = ref(null)

const previewModalHelper = modalHelper()
const proposalService = useProposalService()

/**
 * Share and preview should be disabled unless user has added at least one item (A route or Yacht)
 * has been added to the selection and has reached the review step
 */
const shareAndPreviewDisabled = computed(() => {
  // TODO: Wire this up - for now, return disabled
  return true

  // If there is nothing don't enable share
  // if (!props.jsonProposal?.document.slides.length) {
  //   return false
  // }

  // return props.jsonProposal.document.slides.some((slide: any) => {
  //   return slide.sections.some(
  //     (section: any) => section.state === EditableSectionState.NEEDS_INIT && section.type !== SectionType.NAV_BAR,
  //   )
  // })
})

onMounted(() => {
  if (route.name === 'trips-editor-presentation-new') {
    createNewPresentation()
  } else if (route.name === 'trips-editor-presentation-duplicate' && props.uri) {
    duplicate(props.uri)
  }
})

// Creates a new presentation and navigates to the edit page
const createNewPresentation = async () => {
  showInfoToast('Creating new presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/presentation/new', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({ name: 'trips-editor-presentation-edit', params: { uri } })
  } else {
    showErrorToast('Failed to create new presentation')
    console.debug('Failed to create new presentation', res)
  }
}

// Duplicates a presentation and navigates to the edit page
const duplicate = async (fromUri: string) => {
  showInfoToast('Duplicating presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`/api/presentation/duplicate/${fromUri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({
      name: 'trips-editor-presentation-edit-routes',
      params: { uri },
      query: { addItem: route.query?.addItem },
    })
  } else {
    showErrorToast('Failed to duplicate presentation')
    console.debug('Failed to duplicate presentation', res)
  }
}

const share = (domain: string, uri: string) => {
  // hide the modal
  previewModalHelper.hide()
  // share & show toast
  showAwaitToast(
    proposalService.share(uri),
    `Creating link to share <em>${presentationNameEl.value?.innerText}</em>...`,
    `Link created and copied to your clipboard.`,
    `<em>${presentationNameEl.value?.innerText}</em>, shareable link could not be copied.`,
  ).then((trip: Trip) => {
    copy(`${domain}/${trip.bookingId}/proposal`)
  })
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div
          class="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-4 border-b px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <!-- nunununununununun -->
          <!--   Breadcumbs      -->
          <!-- nunununununununun -->
          <div class="min-w-fit">
            <nav>
              <ol class="flex flex-row flex-nowrap shrink-0 items-center">
                <li class="mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm font-medium hover:fill-primary-600 dark:hover:fill-primary-500"
                    :to="{ name: 'trips-library-presentations' }"
                  >
                    <SolidHome
                      class="w-4 h-4 transition-all hover:fill-primary-600 hover:stroke-primary-600 dark:hover:fill-primary-500 dark:hover:stroke-primary-500 fill-gray-500 stroke-gray-500 dark:fill-gray-400 dark:stroke-gray-400"
                    />
                  </RouterLink>
                </li>
                <li class="mr-2 text-sm text-gray-500 dark:text-gray-400">Presentations</li>
                <li class="mr-2 text-sm text-gray-500 dark:text-gray-400">/</li>
                <li
                  contenteditable
                  ref="presentationNameEl"
                  class="rounded-md text-sm text-gray-700 dark:text-gray-300 focus:border-primary-600 dark:focus:border-primary-500"
                >
                  {{ presentationNameEl?.innerText || 'Untitled New Presentation' }}

                  <!-- <input
                    type="text"
                    class="min-w-fit w-full rounded-md text-sm p-0 border-0 focus:border bg-transparent text-gray-700 dark:text-gray-300 focus:border-primary-600 dark:focus:border-primary-500"
                    v-model="presentationName"
                  /> -->
                </li>
              </ol>
            </nav>
          </div>

          <!-- nunununununununun -->
          <!--       Views       -->
          <!-- nunununununununun -->
          <ul class="flex flex-nowrap -mb-px text-center font-medium text-sm text-gray-500 dark:text-gray-400">
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-routes', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-routes'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidRoute class="size-4 me-2" />Route
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-yachts', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-yachts'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidYacht class="size-4 me-2" />Yacht
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group"
                :to="{ name: 'trips-editor-presentation-edit-design', params: { uri: props.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-design'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidColorSwatch class="size-4 me-2" />Design
              </RouterLink>
            </li>
          </ul>

          <div class="flex items-center gap-x-2">
            <!-- Diff sync saved state -->
            <span class="font-medium text-xs text-gray-900 dark:text-gray-50">Saved</span>
            <!-- nunununununununun -->
            <!--    Button Group   -->
            <!-- nunununununununun -->
            <div class="flex items-center gap-x-2">
              <!-- Preview -->
              <RouterLink
                target="_blank"
                class="transition-colors flex items-center gap-x-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center border text-white border-gray-200 dark:border-gray-600"
                :to="{ name: 'trips-preview', params: { uri: encodeURIComponent(props.uri!) } }"
                :class="
                  shareAndPreviewDisabled
                    ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                    : 'cursor-pointer focus:ring-4 focus:outline-none bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700'
                "
                :disabled="shareAndPreviewDisabled"
              >
                <OutlineEye class="w-4 h-4 shrink-0" />
                Preview
              </RouterLink>

              <!-- Share -->
              <button
                class="transition-all flex items-center gap-x-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-blue-300 text-white hover:text-white"
                :class="
                  shareAndPreviewDisabled
                    ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                    : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-blue-500 dark:hover:bg-primary-800'
                "
                :disabled="shareAndPreviewDisabled"
                @click="share($config.WAVE_URL, props.uri!)"
              >
                <OutlineShare class="w-4 h-4 shrink-0" />
                Share
              </button>

              <!-- Profile -->
              <AccountMenu
                :name="authenticationContext.getUser()?.given_name"
                @logout="$authenticationContext.logout()"
              />
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <div v-if="$route.name === 'trips-editor-presentation-new'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-presentation-duplicate'"><Loader /></div>
        <div v-else>
          <div v-if="$route.query?.addItem">Item to add {{ $route.query.addItem }}</div>
          <RouterView name="content" />
        </div>
      </template>
    </OneColumnWithHeader>
  </div>
</template>
